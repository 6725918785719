<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <h5 style="text-align: center">{{ message }}</h5>
    <!--          Members-->
    <div class="row">
      <div class="col-md-12">
        <paginated-select
          :labelTitle="'Members'"
          :isMultiple="false"
          :apiUrl="'/members/paginatedMembers'"
          @select-changed="setMemberId"
          :key="paginatedCompKey"
        >
        </paginated-select>
      </div>
    </div>

    <div v-if="is_register_modal" class="form-group pt-4">
      <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Guest?') }}</label>&nbsp;
      <l-switch v-model="is_guest" :class="{ 'disabled-switch': cost === 0 }">
        <i class="fa fa-check" slot="on"></i>
        <i class="fa fa-times" slot="off"></i>
      </l-switch>
    </div>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import PaginatedSelect from "@/components/paginatedSelect";
import { Switch as LSwitch} from "@/components";

export default {
  name: 'add-registration-modal',

  components: {
    PaginatedSelect,
    [Dialog.name]: Dialog,
    FgSelect,
    LSwitch
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    message: {
      type: String,
      default: "Select user",
    },
    is_register_modal: {
      type: Boolean,
      default: false
    },
    cost: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      member_id: null,
      is_guest: this.cost <= 0,
      paginatedCompKey: 0,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmModal() {
      let data = this.member_id
      if (this.is_register_modal){
        data = {member: this.member_id, is_guest: this.is_guest}
      }
      this.$emit('confirm', data);
    },
    setMemberId(memberId) {
      this.member_id = memberId
    },

  }

}
</script>

<style>
.disabled-switch {
  pointer-events: none;
  opacity: 0.5;
}
</style>
