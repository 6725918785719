<template>
  <div class="row">
    <div class="col-12">
      <card :key="componentKey" >
        <div slot="header" class="col-12 d-flex flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Waiting List') }}</h4>
          <span style="margin-left: 10px;color: #3f3b3b;margin-top: 5px;" v-html="itemName"></span>
        </div>
        <div class="d-flex justify-content-end">
          <a v-if="$store.getters['auth/haveOneOfPermissions'](['waiting-lists/create'])"
             class="btn btn-info btn-wd btn-simple btn-link"
             style="background-color: #23CCEF !important; color: #ffffff !important;"
             @click="openAddRegistrationModal()">
            {{ $store.getters['languageMixin/getStringFromLanguage']('Add New Member to waiting list') }}
            <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span></a>
        </div>

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="apiUrl">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">



              <template slot-scope="scope">
                <span v-if="column.value === 'member.full_name_cms'" v-html="scope.row['member'].full_name_cms"></span>
                <span v-else>{{scope.row[column.value]}}</span>

              </template>

            </el-table-column>





            <el-table-column
              :min-width="120"
              align="center"
              fixed="right"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <template>
                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['waiting-lists/move-to-registration'])"
                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Move To Registration')"
                     class="btn-success btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id, 'move')">
                    <i class="fa fa-share"></i></a>
                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['waiting-lists/delete'])"
                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Delete')"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id, 'delete')">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>

          </general-data-table>
        </div>
      </card>
    </div>
    <add-registration-modal :visible="modalVisibility"
                            :is_register_modal="false"
                            :message="$store.getters['languageMixin/getStringFromLanguage']('select a member to add to this waiting list?')"
                  @close="closeAddRegistrationModal()"
                  @confirm="confirmRegistrationModal">
    </add-registration-modal>
    <delete-modal :visible="deleteModalVisibility"
                  :message="deleteMessage"
                  :type="deleteModalType"
                  @close="closeDeleteModal()"
                  @confirm="confirmDeleteModal()">
    </delete-modal>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import AddRegistrationModal from "../../../components/Modals/AddRegistraionModal";


export default {
  components: {
    AddRegistrationModal,
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },
  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Member Name'), value: 'member.full_name_cms', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Source'), value: 'source', minWidth: '200', align: 'center'},
      ],

      filterBuilders: {
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      deleteMessage: '',
      componentKey: 0,
      registrationType: null,
      id: undefined,
      toMoveModalId: undefined,
      modalVisibility: false,
      filters: {
        session_id: null,
        cycle_id: null
      },
      itemName: '',
      deleteModalType: '',

    }
  },
  computed: {
    apiUrl() {
      const { type, id } = this.$route.params;
      return `waiting-lists/index/${id}`;
    }
  },
  async mounted() {


    if (this.$route.params['type'] === 'cycle'){
      this.filters.cycle_id = this.$route.params['id'];
    }else{
      this.filters.session_id = this.$route.params['id'];
    }
    let data = {
      'cycle_id': this.filters.cycle_id,
      'session_id': this.filters.session_id,
    };
    let response = await this.axios.post("waiting-lists/builder", data);
    this.itemName = response.data.name;
    this.itemDays = response.data.days;
    // this.applyFilter();
  },
  methods: {
    openDeleteModal(id, type) {
      this.deleteModalType = type;
      if (type === 'move'){
        this.deleteMessage = this.$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to move this member to registration list?')
      }else{
        this.deleteMessage = this.$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this member from waiting list?')
      }
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    openAddRegistrationModal(id) {
      if (this.filters.session_id || this.filters.cycle_id){
        this.modalVisibility = true;
        this.toMoveModalId = id;
      }else{
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("You have to specify a cycle or session"),
          timeout: 2000,
          type: 'warning'
        });
      }
    },
    closeAddRegistrationModal() {
      this.modalVisibility = false;
    },
    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    async confirmRegistrationModal(member_id) {
      try {
        let data = {
          'member_id': member_id,
          'session_id': this.filters.session_id,
          'cycle_id': this.filters.cycle_id,
        }
         await this.axios.post("waiting-lists/create", data).then(response => {
           this.$notify({
             message: this.$store.getters['languageMixin/getStringFromLanguage']("Registration added successfully"),
             timeout: 1000,
             type: 'success'
           });
           this.$refs.table.getData("updateData");
           this.closeAddRegistrationModal();
           }).catch((error) => {
           console.log(error.response.data)
           if (error.response.status === 400) {
             this.$notify({
               message: error.response.data.message,
               timeout: 1000,
               type: 'danger'
             });
           } else {
           }
         }).finally(() => {
           this.submitting = false;
         });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    async confirmDeleteModal() {
      try {
        let data = {
          'id': this.toDeleteId,
        }
        if (this.deleteModalType === 'move'){
          await this.axios.post("waiting-lists/move", data);
        }else{
          await this.axios.delete("waiting-lists/delete", {headers: {}, data});
        }
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Waiting list updated successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.session_id || this.filters.cycle_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
  },
}
</script>

<style>
  .register_status{
    color: #ffff;
  }
</style>

